import { alpha, createTheme } from "@mui/material/styles";
import {
  aliceBlueColor,
  amberBlazeColor,
  arcticMistColor,
  blackColor,
  brightTurquoiseColor,
  charcoalGrayColor,
  coralRed,
  coralSunset,
  crimsonBlazeColor,
  darkNavyBlueColor,
  emeraldOasisColor,
  errorColor,
  fireEngineRedColor,
  forestShadowColor,
  frostyVeilColor,
  fuchsiaFlareColor,
  goldenCreamColor,
  graphiteGrayColor,
  lavenderGray,
  mintMistColor,
  paleBlueColor,
  peachPinkColor,
  periwinkleBlue,
  primaryColor,
  rosyGlowColor,
  rustRedColor,
  sereneSkyBlueColor,
  silverSageColor,
  slateGrayColor,
  successColor,
  warningColor,
  whiteColor,
} from "../../assets/colors";
import { dmSans, iranSans } from "../../utils/constants";
// --------------------------------------------------------------------------------
// NOTE Create Custom Theme
export const theme = createTheme({
  // direction: "rtl",
  palette: {
    // mode: "dark",
    // background: {
    //   paper: backgroundColor,
    //   default: backgroundColor,
    // },
    // background: {
    //   default: "#FFFFFF",
    // },
    primary: {
      main: primaryColor,
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#ffffff",
    },
    tertiary: {
      main: sereneSkyBlueColor,
      contrastText: primaryColor,
    },

    error: {
      main: errorColor,
    },
    success: {
      main: successColor,
    },
    warning: {
      main: warningColor,
    },
    black: {
      main: blackColor,
    },
    silverSage: {
      main: silverSageColor,
      contrastText: forestShadowColor,
    },
    emeraldOasis: {
      main: mintMistColor,
      contrastText: primaryColor,
    },
    slateGray: {
      main: slateGrayColor,
      // contrastText: emeraldOasisColor,
    },
    arcticMist: {
      main: arcticMistColor,
      contrastText: slateGrayColor,
    },
    frostyVeil: {
      main: frostyVeilColor,
      contrastText: whiteColor,
    },
    arcticMist2: {
      main: arcticMistColor,
      contrastText: forestShadowColor,
    },
    arcticMist3: {
      main: arcticMistColor,
      contrastText: primaryColor,
    },
    arcticMist4: {
      main: arcticMistColor,
      contrastText: darkNavyBlueColor,
    },
    fireEngineRed: {
      main: fireEngineRedColor,
      contrastText: whiteColor,
    },
    aliceBlue: {
      main: aliceBlueColor,
      contrastText: fireEngineRedColor,
    },
    black: {
      main: blackColor,
    },
    brightTurquoise: {
      main: brightTurquoiseColor,
      contrastText: primaryColor,
    },
    charcoalGray: {
      main: charcoalGrayColor,
      contrastText: whiteColor,
    },
    goldenCream: {
      main: goldenCreamColor,
      contrastText: amberBlazeColor,
    },
    rosyGlow: {
      main: rosyGlowColor,
      contrastText: crimsonBlazeColor,
    },
    paleBlue: {
      main: paleBlueColor,
      contrastText: forestShadowColor,
    },
    peachPink: {
      main: peachPinkColor,
      contrastText: rustRedColor,
    },
    transparentTeal: {
      main: alpha(primaryColor, 0.1),
      contrastText: primaryColor,
    },
    lavenderGray: {
      main: lavenderGray,
      contrastText: periwinkleBlue,
    },
    coralRed: {
      main: arcticMistColor,
      contrastText: coralRed,
    },
    arcticMistWhite: {
      main: alpha(arcticMistColor, 0.36),
      contrastText: whiteColor,
    },
    fuchsiaFlare: {
      main: fuchsiaFlareColor,
      contrastText: fuchsiaFlareColor,
    },
    coralSunset: {
      main: coralSunset,
      contrastText: whiteColor,
    },
  },
  typography: {
    fontFamily: [dmSans, iranSans].join(","),
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          fontSize: 14,
          paddingTop: 12.5,
          paddingBottom: 12.5,
        },
        adornedEnd: {
          paddingRight: 6,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          height: 0,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          height: ".867em",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          boxShadow: "none",
          borderRadius: 8,
          "&:hover": {
            boxShadow: "none",
          },
          "&:active": {
            boxShadow: "none",
          },
        },
        contained: {
          paddingTop: ".6em",
          paddingBottom: ".6em",
          // color: "#ffffff",
        },
        text: {
          ":hover": {
            backgroundColor: "transparent",
          },
        },
        sizeSmall: {
          fontSize: 14,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: arcticMistColor,
          borderRadius: 15,
        },
      },
      defaultProps: {
        elevation: 0,
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: forestShadowColor,
          whiteSpace: "pre-line",
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: graphiteGrayColor,
        },
      },
    },
    // MuiPaper: {
    //   styleOverrides: {
    //     root: {
    //       background: "none",
    //     },
    //   },
    // },
  },
});
